.framePage .framePage-body {
  display: block;
  flex: initial;
}
.operationControl > div > div > span,
.operationControl > div span > span {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.topInfo {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}
